<template>
  <div class="box">
    <div class="content">
      <el-form
        ref="formRef"
        label-width="120px"
        :rules="rules"
        :model="form"
        @submit.native.prevent
      >
        <el-form-item label="医生姓名" prop="username">
          <el-input
            v-model="form.username"
            maxlength="12"
            clearable
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="绑定手机号" prop="phoneNo">
          <el-input
            v-model="form.phoneNo"
            clearable
            placeholder="请输入微信授权手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属医院" prop="affiliatedHospital">
          <el-input
            v-model="form.affiliatedHospital"
            maxlength="24"
            clearable
            placeholder="请输入所属医院"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="form.gender" clearable placeholder="请选择性别">
            <el-option
              v-for="item in range"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="医生执业证号" prop="idCardNo">-->
        <!--          <el-input v-model="form.idCardNo" @change="idCardNoChange" clearable placeholder="请输入医生执业证号"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="医生执业证号" prop="idCardNo">
          <el-input
            v-model="form.idCardNo"
            clearable
            placeholder="请输入医生执业证号"
          ></el-input>
        </el-form-item>
        <!--        <el-form-item label="年龄" prop="age">
          <el-input v-model="form.age" clearable placeholder="请输入年龄"></el-input>
        </el-form-item>-->

        <el-form-item label="">
          <div style="margin-left: 24px;color:#606266;font-size:14px'">
            <div>
              <div>AI回复肠道准备情况</div>
              <div class="my-4 flex items-center text-sm">
                <el-radio-group
                  v-model="form.isOpenAi"
                  class="ml-4"
                  @change="radioChange"
                >
                  <el-radio value="0" label="0" size="small">开启</el-radio>
                  <el-radio value="1" label="1" size="small">关闭</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div v-if="form.isOpenAi == 0">
              <div>AI回复是否需要确认</div>
              <div class="my-4 flex items-center text-sm">
                <el-radio-group v-model="form.checkType" class="ml-4">
                  <el-radio value="0" label="0" size="small">需要确认</el-radio>
                  <el-radio value="1" label="1" size="small"
                    >不需要确认</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="医生端功能权限" prop="appletMenuIdsD">
          <el-tree
            :data="doctorTreeData"
            show-checkbox
            default-expand-all
            node-key="appletMenuId"
            ref="doctorTreeRef"
            :props="defaultProps"
            highlight-current
          >
          </el-tree>
        </el-form-item>
        <el-form-item label="患者端功能权限" prop="appletMenuIdsP">
          <el-tree
            @check="nodeClick"
            :data="patientTreeData"
            show-checkbox
            default-expand-all
            node-key="appletMenuId"
            ref="patientTreeRef"
            :props="defaultProps"
            highlight-current
          >
          </el-tree>
        </el-form-item>
        <el-form-item>
          <el-button @click="onSubmit" type="primary">确认</el-button>
          <el-button @click="go_back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { IdCard } from "@/utils/IdCard";
export default {
  name: "doctorManageAdd",
  data() {
    return {
      form: {
        id: "",
        username: "",
        phoneNo: "",
        idCardNo: "",
        affiliatedHospital: "",
        identity: "",
        gender: "",
        age: "",
        appletMenuIdsD: [],
        appletMenuIdsP: [],
        checkType: "0",
        isOpenAi: "0",
      },
      rules: {
        username: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          // { min: 0, max: 12, message: '姓名长度在 0 到 12 位', trigger: 'blur' }
        ],
        phoneNo: [
          { required: true, message: "请输入微信授权手机号", trigger: "blur" },
          {
            pattern: /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/,
            message: "手机号不正确",
          },
        ],
        idCardNo: [
          // { required: true, message: '请输入医生执业证号', trigger: 'blur' },
          // { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: "身份证号不合法" }
        ],
        affiliatedHospital: [
          { required: true, message: "请输入所属医院", trigger: "blur" },
          // { min: 0, max: 24, message: '姓名长度在 0 到 24 位', trigger: 'blur' }
        ],
        appletMenuIdsD: [
          { required: true, message: "请选择医生端功能权限", trigger: "blur" },
        ],
        appletMenuIdsP: [
          { required: true, message: "请选择患者端功能权限", trigger: "blur" },
        ],
        identity: [{ required: true, message: "请选择身份", trigger: "blur" }],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
      },
      identity_list: [
        {
          value: "0",
          label: "全科医生",
        },
        {
          value: "1",
          label: "肠道医生",
        },
      ],
      range: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      doctorTreeData: [],
      patientTreeData: [],
      defaultProps: {
        children: "children",
        label: "menuName",
      },
    };
  },
  created() {
    this.getTree();
    let id = this.$route.query.id;
    if (id) {
      this.get_details(id);
    }
  },
  mounted() {},
  methods: {
    nodeClick(node) {
      console.log(node);

      // console.log(this.$refs.patientTreeRef.getCheckedKeys());
      let str = node.appletMenuId;
      let arr = this.$refs.patientTreeRef.getCheckedKeys();
      if (str == "16") {
        if (arr.includes("3")) {
          console.log("包含16");
          this.$refs.patientTreeRef.setChecked(3, false);
          arr.forEach((item, index) => {
            if (item == "3") {
              arr.splice(index, 1);
            }
          });
          console.log(arr);

          this.$refs.patientTreeRef.setCheckedKeys(arr);
        }
      } else if (str == "3") {
        if (arr.includes("16")) {
          console.log("包含3");
          this.$refs.patientTreeRef.setChecked(16, false);
          arr.forEach((item, index) => {
            if (item == "16") {
              arr.splice(index, 1);
            }
          });
          console.log(arr);

          this.$refs.patientTreeRef.setCheckedKeys(arr);
        }
      }
    },
    get_details(id) {
      this.$get(`docmanage/detail/${id}`).then((res) => {
        if (res) {
          console.log(res);
          let data_ = res.data;
          this.$nextTick(() => {
            this.form.id = data_.id;
            this.form.username = data_.docName;
            this.form.phoneNo = data_.phoneNo;
            this.form.idCardNo = data_.idCardNo;
            this.form.gender = data_.gender;
            this.form.affiliatedHospital = data_.affiliatedHospital;
            this.form.checkType = data_.checkType;
            this.form.isOpenAi = data_.isOpenAi;
            let appletMenuInfosD = (
              data_.appletMenuInfo.appletMenuInfosD || []
            ).map((item) => item.appletMenuId);
            let appletMenuInfosP = (
              data_.appletMenuInfo.appletMenuInfosP || []
            ).map((item) => item.appletMenuId);

            this.$refs.doctorTreeRef.setCheckedKeys(appletMenuInfosD);
            this.$refs.patientTreeRef.setCheckedKeys(appletMenuInfosP);
          });
        }
      });
    },
    onSubmit() {
      this.form.appletMenuIdsD = this.$refs.doctorTreeRef.getCheckedKeys();
      this.form.appletMenuIdsP = this.$refs.patientTreeRef.getCheckedKeys();
      console.log(this.form);
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          console.log(this.form);
          this.$post("docmanage/save", this.form).then((res) => {
            if (res) {
              console.log(res);
              this.$message.success("添加成功");
              this.go_back();
            }
          });
        }
      });
    },
    radioChange(e) {
      console.log(e);
      if (e == 1) {
        this.form.checkType = "0";
      }
    },
    getTree() {
      this.$get("am/getappletmenuinfo").then((res) => {
        console.log(res);
        let data = res.data;
        this.doctorTreeData = data.appletMenuInfosD;
        this.patientTreeData = data.appletMenuInfosP;
      });
    },
    idCardNoChange(e) {
      this.$refs.formRef.validateField("idCardNo", (valid) => {
        if (!valid) {
          this.form.gender = IdCard(e, 2);
          this.form.age = IdCard(e, 3);
          this.form.birth = IdCard(e, 1);
        } else {
          this.form.gender = "";
          this.form.age = "";
          this.form.birth = "";
        }
      });
    },
    go_back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding: 24px;
  background: #ffffff;
}

.content {
  width: 45%;

  .el-select {
    width: 100%;
  }
}
</style>
